import { SToast } from '@shein-aidc/sui-toast/mobile'
import { nextTick } from 'vue'
/* globals gb_select_country_enabled */
import creator from 'public/src/pages/components/address/index.js'
import storeAddressVue from 'public/src/pages/components/address/store_index.vue'
import { analysis } from '../detail/analysis/ga'
import { TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import orderLogic from 'public/src/pages/common/orderLogic'
import { htmlDecode, isFunction, cloneDeep } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { updateOrderListOrDetailAddress } from 'public/src/pages/trackNew/utils'
import { EventBus } from 'public/src/pages/common/event-bus.js'
import { isRelationBillno, getSuccessHandleTipText, getErrorHandleTipText, getEditAddressConditionConfig, getModifyAddressInfoEvt } from 'public/src/pages/user/child_pages/orders/common/orderAddressNewTools'
import RetryAddress from 'public/src/pages/components/address/retry-address.vue'

let { langPath } = typeof window != 'undefined' ? gbCommonInfo : {}

export default {
  data() {
    return {
      orderAddress: {
        type: 1, // 1:成功 2:失败
        show: false,
        text: ''
      },
      addressShow: false,
      editAddressType: 'shipping',
      disabledAddress: ['country'],
      extraAddress: ['isDefault', 'type', 'certificateTw'],
      storeCache: {},
      countryList: [],
      item_cates: [],
      isCodAddressEditEnable: true,
      batchModifyAddressData: {},
      batchModifyAddressParams: {},
      showEditAddressConfirmDialog: false,
      batchAddressMessage: '', // 地址编辑成功弹窗文案
      showBatchAddressMessage: false, // 显示地址编辑成功弹窗
      addressEditFlag: false, // 地址编辑标识
      editSuccessFlag: false, // 地址编辑成功标识
    }
  },
  components: {
    addressVue: creator,
    storeAddressVue,
    RetryAddress
  },
  async mounted() {
    await this.getCountryListInfo()
  },
  computed: {
    editAddressFlag() {
      return order => {
        return orderLogic.editAddressFlag(order, this.countryList, this.isCodAddressEditEnable)
      }
    },
    // 是否有编辑BILL地址按钮
    editBillAddressFlag() {
      const isSupportCountry = this.countryList.indexOf(+this.orderInfo.country_id) > -1
      const isCod = this.orderInfo?.payment_method === 'cod'
      if (!isCod) {
        return isSupportCountry
      }
      const orderStatus = +this.orderInfo?.orderStatus
      return isSupportCountry && [0, 12, 13].includes(orderStatus) && this.isCodAddressEditEnable
    },
    editAddressNewConfig () {
      if (['refundReason', 'orderDetail'].includes(this.moduleName)) {
        const addressNeedBillno = this.isUnPaiMoreAddressOrderForDetail && this.addressBillno ? this.addressBillno : this.orderInfo?.billno
        const currentOrder = orderLogic.getEditAddressConfig(this.editAddressNewConfigList, addressNeedBillno)
        return currentOrder || {}
      } else {
        return this.orderInfo?.editAddressNewConfigList?.[0] || {}
      }
    },
    isNewEditAddressFlag () {
      if (!this.showEditOrderAddress) return false
      return this.editAddressNewConfig?.is_order_address_editable == 1
    },
    // * 订单详情页 未支付多地址场景
    isUnPaiMoreAddressOrderForDetail () {
      return this.isUnPaiMoreAddressOrder && this.moduleName === 'orderDetail'
    },
  },
  methods: {
    htmlDecode: text => htmlDecode({ text }),
    // *更新订单列表信息
    updateAddressList(info = {}) {
      if (!this.isUnPaiMoreAddressOrderForDetail) return 
      const addressItem = this.orderInfo?.address_list[this.methodTabSelected] || {}
      const newAddressList = [...(this.orderInfo?.address_list || [])]
      const newAddressInfo = {
        ...addressItem,
        ...info,
        shipping_address1: info.shipping_address_1 || '',
        shipping_address2: info.shipping_address_2 || '',
        shipping_tax_number: info.taxNumber || info.tax_number || '',
        shipping_national_id: info.nationalId || '',
      }
      newAddressList[this.methodTabSelected] = newAddressInfo
      this.assignState({
        orderInfo: { 
          ...this.orderInfo, 
          address_list: newAddressList
        }
      })
    },
    
    // 地址编辑成功弹窗确认
    closeBatchAddressDialog() {
      // 是否在订单取消页面
      const isCancel = SaPageInfo?.page_name == 'page_cancel_order' || this.matchCompName === 'refund-item'
      if (!isCancel && this.addressEditFlag) {
        this.confirmDialogHandle()
      } else if (isCancel && this.editSuccessFlag) {
        this.addressShow = false
        this.toJumpPage()
      }
    },
    clickEditAddress () {
      var self = this
      var info = this.orderInfo
      var now_address = {
        fname: info.shipping_firstname,
        lname: info.shipping_lastname,
        fatherName: info.shipping_father_name,
        englishName: info.subsidiary.english_name,
        countryId: info.shipping_country_id,
        state: info.shipping_province,
        city: info.shipping_city,
        district: info.shipping_district,
        street: info.shipping_street,
        address1: info.shipping_address_1,
        address2: info.shipping_address_2,
        postcode: info.shipping_postcode,
        taxNumber: info.tax_number,
        nationalId: info.nationalId,
        tel: info.shipping_telephone,
        new_tel: info.shipping_telephone,
        standbyTel: info.backupShippingTelephone,
        birthday: info.birthday,
        isDefault: '',
        type: info.type,
        middleName: info.subsidiary.middle_name,
        stateId: info.shipping_state_id,
        cityId: info.shipping_city_id,
        districtId: info.shipping_district_id,
        passportNumber: info.subsidiary.passport_number,
        passportIssuePlace: info.subsidiary.issue_place,
        passportIssueDate: info.subsidiary.issue_date,
        lat: info.shipping_lat,
        lng: info.shipping_lng,
        calendar_type: info.calendar_type,
        id_expire_date: info.id_expire_date
      }
      self.editAddressType = 'shipping'
      self.extraAddress =
        info.shipping_country_id == 209
          ? ['isDefault', 'certificateTw']
          : ['isDefault', 'type', 'certificateTw']
      if (
        self.orderInfo?.payment_method === 'cod' &&
        [6, 4].includes(+self.orderInfo?.orderStatus)
      ) {
        self.disabledAddress = [...self.disabledAddress, 'tel', 'standbyTel']
      }
      if (self.deliveryEditAddressCond == 3) {
        let { subsidiary, orderStatus, country_id } = info || {}
        let { transport_type } = subsidiary || {}
        let disabledAddressArr = this.getDisabledConfig({ country_id, transport_type, orderStatus })
        self.disabledAddress = [...new Set([...self.disabledAddress, ...disabledAddressArr])]
      }

      const needAssociatedAddress =
        self.disabledAddress.length &&
        self.disabledAddress.some(item => !['country', 'tel', 'standbyTel'].includes(item))
      if (needAssociatedAddress) {
        self.extraAddress.push('associated_address')
      }
      // * 订单详情页 未支付多地址场景强制传值
      if (this.isUnPaiMoreAddressOrderForDetail) {
        this.batchModifyAddressParams = { modify_type: 1 }
      }
      const addressNeedBillno = this.isUnPaiMoreAddressOrderForDetail && this.addressBillno ? this.addressBillno : this.orderInfo?.billno
      if (TRANSPORT_TYPE_SHOP.includes(info.subsidiary.transport_type)) {
        now_address.storeType = info.subsidiary.store_type
        now_address.storeId = info.subsidiary.store_id
        now_address.storeName = info.subsidiary.store_name
        now_address.editAddressFlag = self.deliveryEditAddressCond == 3
        const extraData = orderLogic.getStoreAddressExtraData({ order: this.orderInfo }) || {}
        nextTick(() => {
          const pageSourceLists = {
            page_order_detail: 'orderDetails',
            page_order_list: 'orderlist',
            page_cancel_order: 'refund'
          }
          this.$refs.storeAddress.updateUI({
            type: 'edit',
            addrData: now_address,
            url: `/api/checkout/editShipping/update?billno=${addressNeedBillno}`,
            ajaxType: 'post',
            countryId: now_address.countryId,
            extra: ['certificateTw'],
            disabled: self.disabledAddress,
            pageName: '订单详情页',
            extraParams: this.batchModifyAddressParams,
            extraData: {
              ...extraData,
              billno: addressNeedBillno,
              pageSource: pageSourceLists?.[SaPageInfo?.page_name] || 'orderDetails',
              riskData: {
                order_no: addressNeedBillno,
                is_paid: [0, 12, 13].includes(+this.orderInfo?.orderStatus) ? false : true,
                modify_type: this.batchModifyAddressParams?.modify_type,
                cross_type: this.batchModifyAddressParams?.cross_type,
                pageType: this.moduleName === 'orderList' ? 'orderlist' : this.moduleName === 'refundReason' ? 'refundReason' : 'orderDetails'
              }
            }
          })
        })
      } else if (this.$refs.addressVue) {
        analysis.clickAdressEdit()
        this.addressShow = true
        this.now_address = now_address
        nextTick(() => {
          this.$refs.addressVue.updateUI('edit', {
            ...(now_address || {}),
            otherData: {
              riskData: {
                order_no: addressNeedBillno,
                is_paid: [0, 12, 13].includes(+this.orderInfo?.orderStatus) ? false : true,
                modify_type: this.batchModifyAddressParams?.modify_type,
                cross_type: this.batchModifyAddressParams?.cross_type
              }
            }
          })
          this.sendAddressAnalysis(
            {
              page_type: 'edit',
              activity_from: 'checkout_active'
            },
            { type: 'expose' }
          )
        })
      }
      if (typeof window !== 'undefined' && typeof gb_select_country_enabled !== 'undefined') {
        window.gb_select_country_enabled = false // 国家不可更改
      }
    },
    getDisabledConfig({ country_id, orderStatus, transport_type }) {
      let orderStatusCond = [10].includes(+orderStatus)
      // 均不为processing || out_of_stock状态
      let processOrStockPackage = this.orderInfo?.subOrderStatus?.filter(item =>
        ['processing', 'out_of_stock'].includes(item.packageState)
      )
      let unShipCanDelivery = orderLogic.unShipCanDelivery(
        this.orderInfo,
        this.isInfoFrontAbt,
        processOrStockPackage
      )
      let shipCanDelivery = orderLogic.shipCanDelivery(
        this.orderInfo,
        this.isCanRefundAbt,
        processOrStockPackage
      )
      // 未发货包裹条件
      let unShipCond = unShipCanDelivery && shipCanDelivery
      // 可以修改地址条件1
      let unShipPackageCond =
        orderStatusCond && !processOrStockPackage?.length && this.deliveryEditAddressCond == 3
      // 可以修改地址条件2
      let shippedPackageCond =
        processOrStockPackage?.length && !unShipCond && this.deliveryEditAddressCond == 3
      if (unShipPackageCond || shippedPackageCond) {
        let disabledAddressList = this.updateShippedAddress || {}
        if (!TRANSPORT_TYPE_SHOP.includes(transport_type)) {
          let shippedAddressArr = disabledAddressList['other'][country_id]
          if (shippedAddressArr) {
            let shippedAddressList = this.isObjectEvt(shippedAddressArr)
              ? shippedAddressArr[transport_type]
              : shippedAddressArr
            return shippedAddressList || []
          } else {
            return this.disabledAddress
          }
        } else {
          return disabledAddressList['shop_transit'] || []
        }
      } else {
        return this.disabledAddress
      }
    },
    clickEditBillAddress() {
      if (!this.$refs.addressVue) {
        return
      }
      this.editAddressType = 'billing'
      this.disabledAddress = ['country']
      this.extraAddress = [
        'street',
        'nationalId',
        'fatherName',
        'englishName',
        'district',
        'middleName',
        'standbyTel',
        'taxNumber',
        'type',
        'isDefault',
        'certificateTw',
        'certificatePe'
      ]
      const address = {
        fname: this.orderInfo.firstname,
        lname: this.orderInfo.lastname,
        countryName: this.orderInfo.country_name,
        countryId: this.orderInfo.country_id,
        state: this.orderInfo.province,
        street: this.orderInfo.street,
        city: this.orderInfo.city,
        district: this.orderInfo.district,
        address1: this.orderInfo.address_1,
        address2: this.orderInfo.address_2,
        tel: this.orderInfo.telephone,
        postcode: this.orderInfo.postcode,
        storeType: (this.orderInfo.subsidiary && this.orderInfo.subsidiary.store_type) || ''
      }
      this.addressShow = true
      nextTick(() => {
        this.$refs.addressVue.updateUI('edit', address)
      })
      analysis.clickEditBillingAddress(this.orderInfo.billno)
    },
    showAddressTip: function (type, needShow = true) {
      var self = this
      self.orderAddress.type = type
      self.orderAddress.show = needShow
      let textTips = ''
      if (type == 1) {
        self.addressSuccessText()
      } else {
        if (type == 3) {
          textTips = self.language.SHEIN_KEY_PWA_14909
        } else if (type == 4) {
          textTips = self.language.SHEIN_KEY_PWA_16239
        } else if (type == 5) {
          textTips = self.language.SHEIN_KEY_PWA_16240
        } else {
          textTips = self.language.SHEIN_KEY_PWA_16088
        }
        this.orderAddress.text = this.successEditAddressTip() || textTips
      }
    },
    // 地址成功提示语
    addressSuccessText: function () {
      var url = langPath + '/user/addressbook'
      var text1 = this.language.SHEIN_KEY_PWA_15341 + '!'
      var text2 = this.language.SHEIN_KEY_PWA_15458
      text2 = text2 ? text2.replace('[url]', url) : ''
      this.orderAddress.text = this.successEditAddressTip() || text1 + text2
    },
    orderAddressTipsOk() {
      this.orderAddress.show = false
      if (this.orderAddress.type == '4' || this.orderAddress.type == '5') {
        analysis.clickOkCheckAddress()
      }
    },
    addressEdit(data) {
      if (this.editAddressType == 'shipping') {
        if (this.isNewEditAddressFlag) {
          this.newAddressShippingEdit(data)
        } else {
          this.addressShippingEdit(data)
        }
      } else if (this.editAddressType == 'billing') {
        this.addressBillingEdit(data)
      }
    },
    async addressShippingEditCommonEvt (data = {}, canUpdateFlag) {
      let { isPaid, billno } = this.orderInfo || {}
      let reqBillno = this.isNewEditAddressFlag ? isRelationBillno(this.editAddressNewConfig) : billno
      const addressNeedBillno = this.isUnPaiMoreAddressOrderForDetail && this.addressBillno ? this.addressBillno : reqBillno
      let url = `/api/checkout/editShipping/update?billno=${ addressNeedBillno}`
      let obj = {
        is_paid: isPaid ? 1 : 0,
        ...data,
        ...this.batchModifyAddressParams
      }

      // 判断地址是否有改动 比较一些比较常用的字段
      if (canUpdateFlag && !orderLogic.hasDiffParamsValue(this.now_address, data)) {
        this.addressShow = false
        return {
          isContinue: false
        }
      }

      // [start] 增加一个修改地址字段的打点
      getModifyAddressInfoEvt({
        oldAddressInfo: this.now_address,
        newAddressInfo: data,
        disabledAddressInfo: this.disabledAddress,
        options: {
          billno: reqBillno,
          status: this.orderInfo?.orderStatus,
          scene: this.moduleName?.toUpperCase(),
          tag: `PWA_${this.moduleName?.toUpperCase()}`,
          message: `pwa_${this.moduleName}_modify_disabled_address`
        }
      })
      // [end] 增加一个修改地址字段的打点

      this.$refs.addressVue.loading(true)
      let res = await schttp({
        method: 'POST',
        url: url,
        data: obj
      })
      this.$refs.addressVue.loading(false)
      if (isFunction(this.updateOrderAuthentication)) {
        this.updateOrderAuthentication()
      }
      return {
        isContinue: true,
        obj,
        res
      }
    },
    // * 地址重试结果弹窗确认 
    retryConfirm(componentName){
      if(!this.$refs?.[componentName]) return
      switch(componentName){
        case 'addressVue':
          this.$refs.addressVue?.cancel()
          break
        case 'storeAddress':
          this.$refs.storeAddress?.closeStoreDialog()
          break
        default: break
      }
    },
    async newAddressShippingEdit (data) {
      const retryAddressData = cloneDeep(data)
      let { isContinue, obj, res } = await this.addressShippingEditCommonEvt(data, this.isNewEditAddressFlag)
      if (!isContinue) {
        return
      }

      analysis.editAddress(res.code, res.msg, data.tag, this.orderInfo?.payment_method === 'cod')
      daEventCenter.triggerNotice({
        daId: this.moduleName === 'orderList' ? '1-20-1-1' : '1-19-1-1',
        extraData: {
          address_type: typeof data.tag !== 'undefined' ? data.tag : '',
          is_success: res.code == '0'
        }
      })

      if (res.code == '0') {
        // this.matchCompName 部分退组件第二步的判断 - public/src/pages/user/child_pages/orders/common/cancelItem

        const failInfoList = res.info?.fail_info_list || []
        const successList = res.info?.success_list || []
        if (Array.isArray(failInfoList) && failInfoList.length > 0) {
          // 失败逻辑
          let errorInfo = failInfoList[0] || {}
          let errorCode = errorInfo?.code || 'UNKNOWN_ERROR_CODE'
          let dynamicText = errorInfo?.desc || ''
          let defaultErrorText = this.language?.SHEIN_KEY_PWA_16088

          if (
            successList.length
            && !['300355', '300354', '300356', '300357', '400303', '302359', '111033', '111034'].includes(errorCode)
          ) {
            // 部分成功，重试逻辑
            errorCode = '303103'
          }

          this.$refs.addressVue?.errorHandler({
            code: errorCode,
            options: {
              defaultText: defaultErrorText,
              extraFc: {
                303103: () => {
                  if(!this.$refs?.retryAddress) return
                  this.$refs.retryAddress?.handleRetryAddressInit({ orderInfo: this.orderInfo, callBackFtnName: 'addressShippingEdit', retryAddressData, batchModifyAddressParams: this.batchModifyAddressParams, addressComponentName: 'addressVue' })
                },
                ...orderLogic.getOrderAddressExtra(this.language, dynamicText),
              },
            }
          })
          return
        }

        if (
          SaPageInfo?.page_name == 'page_cancel_order' ||
          this.matchCompName === 'refund-item'
        ) {
          const successText = getSuccessHandleTipText(this.editAddressNewConfig, this.language) || this.language.SHEIN_KEY_PWA_18403
          let isMarkPass = false
          if (isFunction(this.addresShowResumeShipment)) {
            isMarkPass = this.addresShowResumeShipment(res)
          }
          // 编辑订单地址后展示订单标记问询
          if (isMarkPass && isFunction(this.resumeShipmentSleep)) {
            await this.resumeShipmentSleep({
              scene: 'editorderaddress', 
              msg: successText
            })
          }
          
          this.addressEditFlag = false
          this.editSuccessFlag = true
          if (isMarkPass) {
            this.closeBatchAddressDialog()
          } else {
            this.batchAddressMessage = successText
            this.showBatchAddressMessage = true
          }
 
          // 退款修改地址提示
          // this.$toast(this.language.SHEIN_KEY_PWA_18403)
          // setTimeout(() => {
          //   this.toJumpPage()
          // }, 1500)
          return
        }
        
        // 订单地址修改后触发事件
        EventBus.emit('shipping-address-change', { newAddress: data, oldAddress: this.orderInfo })
        let addressDiff = orderLogic.isDiffShippToEdit(this.now_address, data)
        let flag = this.needConfirmDialog && this.needConfirmDialog() && addressDiff
        this.addressEditFlag = flag
        this.editSuccessFlag = true
        if (this.moduleName === 'orderList') {
          this.orders?.forEach(item => {
            if (item.billno == this.orderInfo?.billno) {
              Object.assign(item, updateOrderListOrDetailAddress({ obj }))
            }
          })
        }
        // 临时更新地址字段
        if (this.moduleName === 'orderList') {
          this.orderInfo = {
            ...this.orderInfo,
            ...updateOrderListOrDetailAddress({ obj })
          }
        } else {
          this.assignState({ orderInfo: {
            ...this.orderInfo,
            ...updateOrderListOrDetailAddress({ obj })
          } })
        }
        this.addressShow = false
        if(this.$refs?.retryAddress){
          this.$refs.retryAddress?.clearRetryNum()
        }
        let successText = getSuccessHandleTipText(this.editAddressNewConfig, this.language)
        let isMarkPass = false
        if (isFunction(this.addresShowResumeShipment)) {
          isMarkPass = this.addresShowResumeShipment(res)
        }
        // 编辑订单地址后展示订单标记问询
        if (isMarkPass && isFunction(this.resumeShipmentSleep)) {
          const markRes = await this.resumeShipmentSleep({
            scene: 'editorderaddress', 
            msg: successText
          })
          if (markRes && isFunction(this.refreshOrderInfo)) {
            this.refreshOrderInfo()
          }
        }
        // 重新拉取订单数据
        this.updateOrderInfo()
        // 重新拉取地址编辑条件
        this.updateEditAddressNewConfig()
        // * 特殊场景更新地址数组数据
        this.updateAddressList(this.orderInfo)

        if (successText) {
          if (isMarkPass) {
            this.closeBatchAddressDialog()
          } else {
            this.batchAddressMessage = successText
            this.showBatchAddressMessage = true
          }
        } else {
          if (flag) {
            this.confirmDialogHandle()
          }
        }
      } else {
        this.addressEditFlag = false
        this.editSuccessFlag = false
        this.$refs.addressVue?.errorHandler({
          code: res.code,
          msg: res.msg,
          options: {
            defaultText: getErrorHandleTipText(this.editAddressNewConfig, this.language) || res.tips || this.language?.SHEIN_KEY_PWA_16088,
            extraFc: {
              301107: () => {
                analysis.popUpsCheckAddress()
                return data.is_paid ? this.language.SHEIN_KEY_PWA_16240 : this.language.SHEIN_KEY_PWA_16239
              },
              303103: () => {
                if(!this.$refs?.retryAddress) return
                this.$refs.retryAddress?.handleRetryAddressInit({ orderInfo: this.orderInfo, callBackFtnName: 'newAddressShippingEdit', retryAddressData, batchModifyAddressParams: this.batchModifyAddressParams, addressComponentName: 'addressVue' })
              }
            }
          }
        }) 
      }
    },
    async updateEditAddressNewConfig () {
      let editAddressNewConfigList = await this.getEditAddressConditionConfigEvt([this.orderInfo])
      if (this.moduleName === 'orderList') {
        this.orders?.forEach(item => {
          if (item.billno == this.orderInfo?.billno) {
            let editAddressConditionConfigArr = editAddressNewConfigList.filter(status => status.billno == item.billno)
            item.editAddressNewConfigList = editAddressConditionConfigArr || []
          }
        })
      } else {
        this.assignState({ editAddressNewConfigList })
      }
    },
    async getEditAddressConditionConfigEvt(orders = []) {
      if (!this.showEditOrderAddress) return
      let billnoList = orders.map(item => item.billno)

      let resList = await getEditAddressConditionConfig({
        billnoList,
        sceneType: this.moduleName === 'orderList' ? 'order_list' : 'order_detail'
      })
      
      return resList || []
    },
    showEditAddressDialogEvt (argParams = {}) {
      console.log('showEditAddressDialogEvt', argParams)
      let {
        isShop,
        addressInfo,
        orderInfo,
        options
      } = argParams || {}
      this.editAddressType = 'shipping'
      this.batchModifyAddressParams = options.extraParams || {}
      // * 订单详情页 未支付多地址场景强制传值
      if (this.isUnPaiMoreAddressOrderForDetail) {
        this.batchModifyAddressParams = { modify_type: 1 }
      }

      if (this.moduleName === 'orderList') {
        this.orderInfo = orderInfo
      } else if (isFunction(this.assignState)) {
        const formData = this.isUnPaiMoreAddressOrderForDetail ? {
          ...(orderInfo || {} ),
          billno: this.orderInfo?.billno
        } : orderInfo
        this.assignState({ orderInfo: formData })
      }
      if (isShop) {
        options = {
          ...options,
          pageName: '订单详情页',
          extraData: {
            ...options.extraData,
            pageSource: 'orderDetails',
            riskData: {
              order_no: orderInfo?.billno,
              is_paid: [0, 12, 13].includes(+this.orderInfo?.orderStatus) ? false : true,
              modify_type: this.batchModifyAddressParams?.modify_type,
              cross_type: this.batchModifyAddressParams?.cross_type,
              pageType: this.moduleName === 'orderList' ? 'orderlist' : this.moduleName === 'refundReason' ? 'refundReason' : 'orderDetails'
            }
          }
        }
        nextTick(() => {
          this.$refs.storeAddress.updateUI(options)
        })
      } else if (!isShop && this.$refs.addressVue) {
        let { disabled = [], extra = [] } = options || {}
        this.addressShow = true
        this.disabledAddress = disabled
        this.extraAddress = extra
        this.now_address = addressInfo

        nextTick(() => {
          this.$refs.addressVue.updateUI('edit', {
            ...(addressInfo || {}),
            otherData: {
              riskData: {
                order_no: orderInfo?.billno,
                is_paid: [0, 12, 13].includes(+this.orderInfo?.orderStatus) ? false : true,
                modify_type: this.batchModifyAddressParams?.modify_type,
                cross_type: this.batchModifyAddressParams?.cross_type
              }
            }
          })
        })
      }
    },
    sendAddressAnalysis(extraData = {}, { type = 'save' } = {}) {
      if (this.moduleName !== 'orderDetail') {
        return
      }
      const isCod = this.orderInfo?.payment_method === 'cod'
      daEventCenter.triggerNotice({
        daId: type === 'save' ? '1-19-1-78' : '1-19-1-79',
        extraData: {
          ...extraData,
          address_type: 1,
          order_type: isCod ? 1 : 2
        }
      })
    },
    async addressShippingEdit(data) {
      const retryAddressData = cloneDeep(data)
      let isFullUpdateCondition = this.deliveryEditAddressCond == 3
      // 判断地址是否有改动 比较一些比较常用的字段
      let addressDiff = orderLogic.isDiffShippToEdit(this.now_address, data)
      let { isContinue, obj, res } = await this.addressShippingEditCommonEvt(data, isFullUpdateCondition)
      if (!isContinue) {
        return
      }

      analysis.editAddress(res.code, res.msg, data.tag, this.orderInfo?.payment_method === 'cod')
      daEventCenter.triggerNotice({
        daId: this.moduleName === 'orderList' ? '1-20-1-1' : '1-19-1-1',
        extraData: {
          address_type: typeof data.tag !== 'undefined' ? data.tag : '',
          is_success: res.code == '0'
        }
      })
      if (res.code == '0') {
        // this.matchCompName 部分退组件第二步的判断 - public/src/pages/user/child_pages/orders/common/cancelItem
        if (
          SaPageInfo?.page_name == 'page_cancel_order' ||
          this.matchCompName === 'refund-item'
        ) {
          // 退款修改地址提示
          SToast(this.language.SHEIN_KEY_PWA_18403)
          setTimeout(() => {
            this.toJumpPage()
          }, 1500)
          return
        }
        // 订单地址修改后触发事件
        EventBus.emit('shipping-address-change', { newAddress: data, oldAddress: this.orderInfo })
        const confirmFlag = this.needConfirmDialog && this.needConfirmDialog() && addressDiff
        let isMarkPass = false
        if (isFunction(this.addresShowResumeShipment)) {
          isMarkPass = this.addresShowResumeShipment(res)
        }
        if (confirmFlag) {
          this.showAddressTip(1, false)
        } else {
          this.showAddressTip(1, !isMarkPass)
        }
        // 编辑订单地址后展示订单标记问询
        if (isMarkPass && isFunction(this.resumeShipmentSleep)) {
          const markRes = await this.resumeShipmentSleep({
            scene: 'editorderaddress', 
            msg: this.orderAddress.text
          })
          if (markRes && isFunction(this.refreshOrderInfo)) {
            this.refreshOrderInfo()
          }
        }
        if (this.moduleName === 'orderList') {
          this.orderInfo = {
            ...this.orderInfo,
            ...updateOrderListOrDetailAddress({ obj })
          }
        } else {
          this.assignState({ orderInfo: {
            ...this.orderInfo,
            ...updateOrderListOrDetailAddress({ obj })
          } })
        }
        this.addressShow = false
        if(this.$refs?.retryAddress){
          this.$refs.retryAddress?.clearRetryNum()
        }
        this.updateEditAddressStatus()
        this.updateOrderInfo()

        if (this.isUnPaiMoreAddressOrderForDetail) {    
          // * 特殊场景更新地址数组数据
          this.updateAddressList(this.orderInfo)   
          this.updateEditAddressNewConfig()
        }

        if (confirmFlag) {
          this.confirmDialogHandle()
        }
      } else {
        this.addressEditFlag = false
        this.editSuccessFlag = false
        this.$refs.addressVue?.errorHandler({
          code: res.code,
          msg: res.msg,
          options: {
            defaultText: this.errorEditAddressTip() || res.tips || this.language.SHEIN_KEY_PWA_16088,
            extraFc: {
              301107: () => {
                analysis.popUpsCheckAddress()
                return data.is_paid ? this.language.SHEIN_KEY_PWA_16240 : this.language.SHEIN_KEY_PWA_16239
              },
              303103: () => {
                if(!this.$refs?.retryAddress) return
                this.$refs.retryAddress?.handleRetryAddressInit({ orderInfo: this.orderInfo, callBackFtnName: 'addressShippingEdit', retryAddressData, batchModifyAddressParams: this.batchModifyAddressParams, addressComponentName: 'addressVue' })
              }
            }
          }
        }) 
      }
      const isSuccess = res.code == 0
      this.sendAddressAnalysis({
        result: isSuccess ? 'success' : 'failure',
        failure_type: isSuccess ? '' : 'back',
        failure_reason: isSuccess ? '' : res.code,
        longitude: data.lat || '',
        latitude: data.lng || '',
        association_address: Number(data.tag),
        page_type: 'edit',
        activity_from: 'checkout_active'
      })
    },
    successEditAddressTip() {
      let { successText = '' } = this.deliveryUpdateInfo || {}
      let cond = this.deliveryEditAddressCond == 3
      return cond ? successText || '' : ''
    },
    errorEditAddressTip() {
      let { errorText = '' } = this.deliveryUpdateInfo || {}
      let cond = this.deliveryEditAddressCond == 3
      return cond ? errorText || '' : ''
    },
    // 确认批量修改地址回调
    onConfirmModifyAddress(crossType) {
      this.batchModifyAddressParams = {
        cross_type: crossType,
        modify_type: this.batchModifyAddressData.modify_type
      }
      this.clickEditAddress()
    },
    // 查询批量修改地址信息
    async fetchBatchModifyAddress(billno) {
      if (this.batchModifyAddressAbt) {
        try {
          const res = await schttp({
            method: 'POST',
            url: `/api/orders/query/batchModifyAddress/get`,
            data: { billno }
          })
          if (res?.code == 0 && res?.info) {
            const data = res.info || {}
            // 0-维持现有逻辑，跳地址编辑页进行修改
            if (data.modify_type == 0) {
              this.clickEditAddress()
            } else {
              this.batchModifyAddressData = data
              this.showEditAddressConfirmDialog = true
            }
          } else {
            this.clickEditAddress()
          }
        } catch (e) {
          this.clickEditAddress()
        }
      } else {
        this.clickEditAddress()
      }
    },
    updateOrderInfo(billno) {
      if (billno) {
        this.changeRootStatus({
          loading: true
        })
      }
      schttp({
        url: '/api/orders/base/get_order_info/get',
        params: {
          billno: billno || this.orderInfo.billno
        }
      })
        .then(res => {
          if (res.code == 0 && res.info && res.info.order) {
            const notSupportCodTipsCode =
              (res.info.order.notSupportCodTips && res.info.order.notSupportCodTips.code) || ''
            if (notSupportCodTipsCode !== '') {
              daEventCenter.triggerNotice({
                daId: this.moduleName === 'orderList' ? '1-20-1-3' : '1-19-1-3',
                extraData: {
                  order_id: billno || this.orderInfo.billno,
                  fail_reason: notSupportCodTipsCode
                }
              })
            }
            if (billno) {
              this.batchModifyAddressParams = {}
              if (this.moduleName === 'orderList') {
                this.orderInfo = res.info.order
              } else {
                this.assignState({ orderInfo: res.info.order })
              }
              this.fetchBatchModifyAddress(billno)
            } else {
              if (res.info.order.policyTips) {
                if (this.moduleName === 'orderList') {
                  this.orderInfo = {
                    ...this.orderInfo,
                    policyTips: res.info.order.policyTips,
                    p65_flag: res.info.order.p65_flag || []
                  }
                } else {
                  this.assignState({ orderInfo: {
                    ...this.orderInfo,
                    policyTips: res.info.order.policyTips,
                    p65_flag: res.info.order.p65_flag || []
                  } })
                }
              }
            }
          }
        })
        .finally(() => {
          this.changeRootStatus({
            loading: false
          })
        })
    },
    addressBillingEdit(data) {
      analysis.clickSaveBillingAddress(this.orderInfo.billno)
      this.$refs.addressVue.loading(true)
      var orderInfo = {
        firstname: data.fname,
        lastname: data.lname,
        address_1: data.address1,
        address_2: data.address2,
        city: data.city,
        // street: data.street,
        country_id: data.country_id,
        country_name: data.country_name,
        postcode: data.postcode,
        province: data.state,
        telephone: data.tel
      }
      Object.assign(orderInfo, this.batchModifyAddressParams)
      schttp({
        method: 'POST',
        url: `/api/checkout/updateOrder/update?billno=${this.orderInfo.billno}`,
        data: orderInfo
      }).then(res => {
        this.$refs.addressVue.loading(false)
        if (res.code == 0) {
          // this.matchCompName 部分退组件第二步的判断 - public/src/pages/user/child_pages/orders/common/cancelItem
          if (
            SaPageInfo?.page_name == 'page_cancel_order' ||
            this.matchCompName === 'refund-item'
          ) {
            // 退款修改地址提示
            SToast(this.language.SHEIN_KEY_PWA_18403)
            setTimeout(() => {
              this.toJumpPage()
            }, 1500)
            return
          }
          let {
            firstname = '',
            lastname = '',
            province = '',
            city = '',
            address_1 = '',
            address_2 = '',
            telephone = '',
            postcode = ''
          } = res?.info?.order || {}

          this.orderInfo = {
            ...this.orderInfo,
            firstname,
            lastname,
            province,
            city,
            address_1,
            address_2,
            telephone,
            postcode,
          }
          this.addressShow = false
        } else {
          this.addressEditFlag = false
          this.editSuccessFlag = false
          this.$refs.addressVue?.errorHandler({
            code: res.code,
            msg: res.msg,
            options: {
              defaultText: res.tips,
            }
          })
        }
        daEventCenter.triggerNotice({
          daId: this.moduleName === 'orderList' ? '1-20-1-1' : '1-19-1-1',
          extraData: {
            address_type: res?.info?.order?.tag || '',
            is_success: res.code == 0
          }
        })
        analysis.saveBillingAddress(res.code, res.msg, this.orderInfo.billno)
      })
    },
    updateError() {
      if (this.editAddressType == 'shipping') {
        const isCod = this.orderInfo?.payment_method === 'cod'
        analysis.editAddress(2, 'Address check fail', 0, isCod)
      } else if (this.editAddressType == 'billing') {
        analysis.saveBillingAddress(2, 'Address check fail', this.orderInfo.billno)
      }
    },
    addressCancel() {
      this.addressShow = false
      if(!this.$refs?.retryAddress) return
      this.$refs.retryAddress?.clearRetryNum()
    },
    // * 店配关闭事件
    storeAddressCancel(){
      if(!this.$refs?.retryAddress) return
      this.$refs.retryAddress?.clearRetryNum()
    },
    // * 调用店配地址保存方法
    postStroeAddressData(){
      if(!this.$refs?.storeAddress) return
      this.$refs.storeAddress?.postSubmitData()
    },
    saveStoreAddressEvt (res) {
      if (this.isNewEditAddressFlag) {
        this.newSaveStoreAddress(res)
      } else {
        this.saveStoreAddress(res)
      }
    },
    async newSaveStoreAddress (res) {
      if (res?.code == '0') {
        const failInfoList = res.info?.fail_info_list || []
        const successList = res.info?.success_list || []
        if (Array.isArray(failInfoList) && failInfoList.length > 0) {
          // 失败逻辑
          let errorInfo = failInfoList[0] || {}
          let errorCode = errorInfo?.code || 'UNKNOWN_ERROR_CODE'
          let dynamicText = errorInfo?.desc || ''
          let errorText = orderLogic.getOrderAddressExtra(this.language, dynamicText)?.[errorCode]?.() || this.language?.SHEIN_KEY_PWA_16088

          if (
            successList.length
            && !['300355', '300354', '300356', '300357', '400303', '302359', '111033', '111034'].includes(errorCode)
          ) {
            // 部分成功，重试逻辑
            if(!this.$refs?.retryAddress) return
            this.$refs.retryAddress?.handleRetryAddressInit({ orderInfo: this.orderInfo, callBackFtnName: 'postStroeAddressData', retryAddressData: {}, batchModifyAddressParams: this.batchModifyAddressParams, addressComponentName: 'storeAddress' })
            return
          }

          SToast(errorText)
          return
        }
        let successText = getSuccessHandleTipText(this.editAddressNewConfig, this.language) || this.language?.SHEIN_KEY_PWA_15341
        let isMarkPass = false
        if (isFunction(this.addresShowResumeShipment)) {
          isMarkPass = this.addresShowResumeShipment(res)
        }
        // 编辑订单地址后展示订单标记问询
        if (isMarkPass && isFunction(this.resumeShipmentSleep)) {
          const markRes = await this.resumeShipmentSleep({
            scene: 'editorderaddress', 
            msg: successText
          })
          if (markRes && isFunction(this.refreshOrderInfo)) {
            this.refreshOrderInfo()
          }
        }
        let formData = res?.formData || {}
        if (this.orderInfo?.shipping_country_id == '209' && this.orderInfo?.subsidiary?.store_id != formData.storeId) {
          this.updateStoreAddress(formData.storeId)
        }
        if (this.moduleName === 'orderList') {
          this.orders?.forEach(item => {
            if (item.billno == this.orderInfo?.billno) {
              Object.assign(item, {
                ...updateOrderListOrDetailAddress({ obj: {
                  country_name: item.shipping_country_name,
                  state: item.shipping_province,
                  postcode: item.postcode,
                  address1: item.shipping_address_1,
                  address2: item.shipping_address_2,
                  ...formData
                } }),
                subsidiary: {
                  ...(this.orderInfo?.subsidiary || {}),
                  ...(updateOrderListOrDetailAddress({ obj: formData })?.subsidiary || {})
                }
              })
            }
          })
          this.orderInfo = {
            ...this.orderInfo,
            ...updateOrderListOrDetailAddress({ obj: {
              country_name: this.orderInfo.shipping_country_name,
              state: this.orderInfo.shipping_province,
              postcode: this.orderInfo.postcode,
              address1: this.orderInfo.shipping_address_1,
              address2: this.orderInfo.shipping_address_2,
              ...formData
            } }),
            subsidiary: {
              ...(this.orderInfo?.subsidiary || {}),
              ...(updateOrderListOrDetailAddress({ obj: formData })?.subsidiary || {})
            }
          }
        } else if (this.moduleName === 'orderDetail') {
          this.assignState({ 
            orderInfo: {
              ...this.orderInfo,
              ...updateOrderListOrDetailAddress({ obj: {
                country_name: this.orderInfo.shipping_country_name,
                state: this.orderInfo.shipping_province,
                postcode: this.orderInfo.postcode,
                address1: this.orderInfo.shipping_address_1,
                address2: this.orderInfo.shipping_address_2,
                ...formData
              } }),
              subsidiary: {
                ...(this.orderInfo?.subsidiary || {}),
                ...(updateOrderListOrDetailAddress({ obj: formData })?.subsidiary || {})
              }
            } 
          })
        }
        // * 特殊场景更新地址数组数据
        this.updateAddressList(this.orderInfo)

        // 重新拉取编辑地址条件接口
        this.updateEditAddressNewConfig()
        if(this.$refs?.retryAddress){
          this.$refs.retryAddress?.clearRetryNum()
        }

        this.addressEditFlag = false
        this.editSuccessFlag = true
        if (isMarkPass) {
          this.closeBatchAddressDialog()
        } else {
          this.batchAddressMessage = successText
          this.showBatchAddressMessage = true
          // this.$toast(successText, 5000)
        }
      } else {
        if (res.code == 666666 || res.code == 777777) {
          SToast(this.language.SHEIN_KEY_PWA_17115)
        } else if(res.code == 303103){
          if(!this.$refs?.retryAddress) return
          this.$refs.retryAddress?.handleRetryAddressInit({ orderInfo: this.orderInfo, callBackFtnName: 'postStroeAddressData', retryAddressData: {}, batchModifyAddressParams: this.batchModifyAddressParams, addressComponentName: 'storeAddress' })
        } else {
          let errorText = getErrorHandleTipText(this.editAddressNewConfig, this.language) || res.tips || this.language?.SHEIN_KEY_PWA_16088
          this.addressEditFlag = false
          this.editSuccessFlag = false
          this.batchAddressMessage = errorText
          this.showBatchAddressMessage = true
          //this.$refs.storeAddress.universalToast(errorText)
        }
      }
    },
    async saveStoreAddress(res) {
      if (res.code == 0) {
        if(this.$refs?.retryAddress){
          this.$refs.retryAddress?.clearRetryNum()
        }
        let formData = res.formData
        if (this.orderInfo?.shipping_country_id == '209' && this.orderInfo?.subsidiary?.store_id != formData.storeId) {
          this.updateStoreAddress(formData.storeId)
        }
        if (this.moduleName === 'orderList') {
          this.orders?.forEach(item => {
            if (item.billno == this.orderInfo?.billno) {
              Object.assign(item, {
                ...updateOrderListOrDetailAddress({ obj: {
                  country_name: item.shipping_country_name,
                  state: item.shipping_province,
                  postcode: item.postcode,
                  address1: item.shipping_address_1,
                  address2: item.shipping_address_2,
                  ...formData
                } }),
                subsidiary: {
                  ...(this.orderInfo?.subsidiary || {}),
                  ...(updateOrderListOrDetailAddress({ obj: formData })?.subsidiary || {})
                }
              })
            }
          })
          this.orderInfo = {
            ...this.orderInfo,
            ...updateOrderListOrDetailAddress({ obj: {
              country_name: this.orderInfo.shipping_country_name,
              state: this.orderInfo.shipping_province,
              postcode: this.orderInfo.postcode,
              address1: this.orderInfo.shipping_address_1,
              address2: this.orderInfo.shipping_address_2,
              ...formData
            } }),
            subsidiary: {
              ...(this.orderInfo?.subsidiary || {}),
              ...(updateOrderListOrDetailAddress({ obj: formData })?.subsidiary || {})
            }
          }
        } else if (this.moduleName === 'orderDetail') {
          this.assignState({ 
            orderInfo: {
              ...this.orderInfo,
              ...updateOrderListOrDetailAddress({ obj: {
                country_name: this.orderInfo.shipping_country_name,
                state: this.orderInfo.shipping_province,
                postcode: this.orderInfo.postcode,
                address1: this.orderInfo.shipping_address_1,
                address2: this.orderInfo.shipping_address_2,
                ...formData
              } }),
              subsidiary: {
                ...(this.orderInfo?.subsidiary || {}),
                ...(updateOrderListOrDetailAddress({ obj: formData })?.subsidiary || {})
              }
            } 
          })
        }

        if (this.isUnPaiMoreAddressOrderForDetail) {
          // * 特殊场景更新地址数组数据
          this.updateAddressList(this.orderInfo)
          // 重新拉取编辑地址条件接口
          this.updateEditAddressNewConfig()
        }
        let isMarkPass = false
        if (isFunction(this.addresShowResumeShipment)) {
          isMarkPass = this.addresShowResumeShipment(res)
        }
        // 编辑订单地址后展示订单标记问询
        if (isMarkPass && isFunction(this.resumeShipmentSleep)) {
          const markRes = await this.resumeShipmentSleep({
            scene: 'editorderaddress', 
            msg: this.language.SHEIN_KEY_PWA_15341
          })
          if (markRes && isFunction(this.refreshOrderInfo)) {
            this.refreshOrderInfo()
          }
        } else {
          this.$refs.storeAddress.resultsTipsShow(
            true,
            this.htmlDecode(this.language.SHEIN_KEY_PWA_15341)
          )
        }
        this.updateEditAddressStatus()
      } else if(res.code == 303103){
        if(!this.$refs?.retryAddress) return
        this.$refs.retryAddress?.handleRetryAddressInit({ orderInfo: this.orderInfo, callBackFtnName: 'postStroeAddressData', retryAddressData: {}, batchModifyAddressParams: this.batchModifyAddressParams, addressComponentName: 'storeAddress' })
      } else  {
        if (res.code == 666666 || res.code == 777777) {
          SToast(this.language.SHEIN_KEY_PWA_17115)
        } else {
          let errorText = this.errorEditAddressTip() || res.tips || this.htmlDecode(this.language.SHEIN_KEY_PWA_16088)
          this.addressEditFlag = false
          this.editSuccessFlag = false
          this.batchAddressMessage = errorText
          this.showBatchAddressMessage = true
          // this.$refs.storeAddress.universalToast(errorText)
        }
      }
    },
    updateStoreAddress(storeId) {
      if (this.storeCache[storeId]) {
        this.orderInfo.shipping_address_1 = this.storeCache[storeId].address1
      } else {
        schttp({
          url: '/api/user/addressbook/storeInfoById/get?storeId=' + storeId
        }).then(res => {
          if (res.code == 0) {
            this.storeCache[storeId] = res.info.res
            this.orderInfo.shipping_address_1 = res.info.res.address1
          }
        })
      }
    },
    async getCountryListInfo() {
      let res = await schttp({
        url: '/api/user/addressbook/universalCountryList/get'
      })
      if (res.code == '0') {
        const countryLists = res?.info?.res?.item_country || []
        this.countryList = countryLists.map(item => Number(item.id))
        this.item_cates = countryLists
      } else {
        this.countryList = []
      }
    },
    toJumpPage() {
      const routeName = this.$route?.name
      if (routeName === 'UserOrdersList') {
        location.href = `${langPath}/user/orders/list`
      } else {
        location.href = `${langPath}/user/orders/detail/${this.orderInfo.billno}`
      }
    },
    async updateDeliveryEditAddressCond() {
      this.deliveryEditAddressCond = await this.deliveryGoodsUpdateAddressCond(this.orderInfo)
    },
    updateEditAddressStatus() {
      if (this.deliveryEditAddressCond == 3) {
        this.deliveryEditAddressCond = 2
      }
    },
    isObjectEvt(obj) {
      return Object.prototype.toString.call(obj) === '[object Object]'
    }
  }
}
